import React, { FormEvent, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { BASE_PAGE_TILE, THE_SECRET_CODE } from '../../lib/constants';
import Select from 'react-select';

import imageM from '../../images/check-availability-m.jpg';
import imageD from '../../images/check-availability-d.jpg';
import Button from '../../components/Button/Button';
import FormContainer5050 from '../../components/FormContainer5050/FormContainer5050';

import VetroService from '../../lib/vetro.service';
import APIService from '../../lib/api.service';

import Communities from "./Communities.json";

const CheckAvailabilityPage = (): JSX.Element => {
    const formRef = useRef<HTMLFormElement | null>(null);
    const formContactRef = useRef<HTMLFormElement | null>(null);

    // Forms/Landing pages
    const [showAddressForm, setShowAddressForm] = useState(true);
    const [showContactForm, setShowContactForm] = useState(false);
    const [showCommunityForm, setShowCommunityForm] = useState(false);
    const [showInsideServicableCities, setShowInsideServicableCities] = useState(false);
    const [showTooManyVetroResults, setShowTooManyVetroResults] = useState(false);
    const [showSubmissionSuccess, setShowSubmissionSuccess] = useState(false);
    const [showOutsideServiceArea, setShowOutsideServiceArea] = useState(false);
    const [showDuplicateSignUp, setShowDuplicateSignUp] = useState(false);
    const [showNetworkError, setShowNetworkError] = useState(false);

    // Optional form inputs
    const [showServiceLevelDropdown, setShowServiceLevelDropdown] = useState(true);
    const [showCampaign, setShowCampaign] = useState(false);

    // Dropdown values
    const [vetroAddressData, setVetroAddressData] = useState<any>({});
    const [vetroAddressOptions, setVetroAddressOptions] = useState<any>([]);
    const [vetroUnitOptions, setVetroUnitOptions] = useState<any>([]);

    const [communityAddressOptions, setCommunityAddressOptions] = useState<any>([]);
    const [communityUnitOptions, setCommunityUnitOptions] = useState<any>([]);
    const [communityFormIsSearchable, setCommunityFormIsSearchable] = useState(true);
    const [communityUnitValue, setCommunityUnitValue] = useState("");

    // Errors/prompts
    const [showAvailablePrompt, setShowAvailablePrompt] = useState(false);
    const [showEmailPrompt, setShowEmailPrompt] = useState(false);
    const [showCommunityValueWarning, setShowCommunityValueWarning] = useState(false);

    const [showAnim, setShowAnim] = useState(false);
    const [formData, setFormData] = useState<FormData>();

    const [communityName, setCommunityName] = useState("");

    let parser = require('parse-address');
    const vetroSearchMinLength = 3;

    const serviceableCities = ["tustin", "dana point"]; // TODO: Probably should have this checked on some backend instead.
    const serviceableCommunities = ["Terra at Tustin"];

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        let data = {};
        for (const pair of (formData as FormData).entries()) {
            data[`${pair[0]}`] = `${pair[1]}`;
        }

        if (data["street"] !== undefined && data["street"].length >= vetroSearchMinLength) {
            setShowAnim(true); // Set spinner
            let parsed = parser.parseLocation(data["street"]); // Parse addr input

            let queryString = vetroAddrQueryBuilder(parsed, data["unit"], true, true); // Check exact street addr first, avoid returning too many results with similar addresses (i.e. 1 Redcrown vs. 16 Redcrown)
            let vetroAddressesObj = await VetroService.getVetroAddressMatches(JSON.stringify({"query": queryString})); // Check Vetro

            // If no strict matches found, be a little more lenient and do a "contains" string match instead
            if (vetroAddressesObj["result"].items.length !== 1) {
                queryString = vetroAddrQueryBuilder(parsed, data["unit"], true, false);
                vetroAddressesObj = await VetroService.getVetroAddressMatches(JSON.stringify({"query": queryString})); // Check Vetro (again)
            }

            if (vetroAddressesObj["result"].items.length && Object.prototype.hasOwnProperty.call(vetroAddressesObj["result"].items[0].attributes, "Subname") && serviceableCommunities.includes(vetroAddressesObj["result"].items[0].attributes["Subname"])) {
                var communityName = vetroAddressesObj["result"].items[0].attributes["Subname"];
                var communityAddress = vetroAddressesObj["result"].items[0].attributes["Street Address"]
                setShowAddressForm(false);
                setShowCommunityForm(true);

                setCommunityName(communityName);
                setCommunityUnitOptions(Communities[communityName][communityAddress]["unitSelectValues"]);

                setShowAnim(false);

            } else if (vetroAddressesObj["result"].items.length === 1) { // If we have a match, log it. TODO: Check to see if account is already set up
                // Update Vetro that address has been searched, increase hitcounter.
                let hitCounter = 1;
                if (Object.prototype.hasOwnProperty.call(vetroAddressesObj["result"].items[0].attributes, "Hitcounter")) {
                    hitCounter += parseInt(vetroAddressesObj["result"].items[0].attributes["Hitcounter"]);
                }
                let hitCounterObj = {
                    "features": [
                        {
                        "type": "Feature",
                        "x-vetro": {
                            "vetro_id": vetroAddressesObj["result"].items[0].vetro_ids[0]
                            },
                            "properties": {
                                "Hitcounter": hitCounter.toString()
                            }
                        }
                    ]
                }
                VetroService.updateVetroFeature(JSON.stringify(hitCounterObj));

                // Redirect to COS Marketplace if servicable and Obj exists
                if (vetroAddressesObj["result"].items[0].attributes["Serviceable"] && (vetroAddressesObj["result"].items[0].attributes["Serviceable"] === "Yes" || vetroAddressesObj["result"].items[0].attributes["Serviceable"] === "In construction")) {
                    if (Object.prototype.hasOwnProperty.call(vetroAddressesObj["result"].items[0].attributes, "COS Object ID")) {
                        let cosUrl = `https://citysidefiber.broadbandportal.net/Home/SearchAddress?objectId=${vetroAddressesObj["result"].items[0].attributes["COS Object ID"]}`;
                        window.location.replace(cosUrl);
                        return;
                    } else if (Object.prototype.hasOwnProperty.call(vetroAddressesObj["result"].items[0].attributes, "COS Object Number")) {
                        let cosUrl = `https://citysidefiber.broadbandportal.net/Home/SearchAddress?objectNumber=${vetroAddressesObj["result"].items[0].attributes["COS Object Number"]}`;
                        window.location.replace(cosUrl);
                        return;
                    }
                }

                setShowAnim(false);

                let cur = vetroAddressesObj["result"].items[0].attributes;
                let unit = "";
                if (Object.prototype.hasOwnProperty.call(cur, "Unit Number")) {
                    unit = titleCaseString(cur["Unit Number"]);
                }

                let address = `${titleCaseString(cur["Street Address"])}, ${titleCaseString(cur["City"])}, ${cur["State"]} ${cur["Zip"]}`;

                setVetroAddressData({
                    "vserviceid": vetroAddressesObj["result"].items[0]["vetro_ids"][0], // yes, IDs are in an array lol
                    "vserviceloc": vetroAddressesObj["result"].items[0].attributes["ID"],
                    "serviceable": vetroAddressesObj["result"].items[0].attributes["Serviceable"],
                    "UTYPE": data["type"],
                    "address": address,
                    "campaign": data["campaign"],
                });

                setShowAddressForm(false);
                setShowContactForm(true);

                // Check if city is outside serviceable area
                if (serviceableCities.includes(vetroAddressesObj["result"].items[0].attributes["City"].toLowerCase())) {
                    setShowInsideServicableCities(true);
                }

                // Check if Resi or Business was selected
                if (data["type"] === "B") {
                    setShowAddressForm(false);
                    setShowServiceLevelDropdown(false);
                }
            } else if (vetroAddressesObj["result"].items.length > 1) {
                setShowAnim(false);
                setShowTooManyVetroResults(true);
            } else { // Otherwise, let user know that the input is either incorrect or we don't service that location.
                setShowAnim(false);
                setShowAddressForm(false);
                setShowOutsideServiceArea(true);
            }
        } else {
            return false;
        }
    }

    const onContactSubmit = async (e: FormEvent) => {
        e.preventDefault();

        setShowAnim(true); // Set spinner
        setShowEmailPrompt(false);

        let data = {};
        for (const pair of (formData as FormData).entries()) {
            data[`${pair[0]}`] = `${pair[1]}`;
        }

        // Some browser/browser plguins get past HTML5 validators. Do our own regex check to make sure we've got a valid email.
        let validEmail = new RegExp(/\S+@\S+\.\S+/);
        if (!validEmail.test(data["email"])) {
            setShowAnim(false);
            setShowEmailPrompt(true);
            return;
        }

        data["datestamp"] = new Date().toJSON().slice(0, 10); // "YYYY-MM-DD"
        data["vserviceloc"] = vetroAddressData["vserviceloc"]; // Pulled from previous form
        data["serviceable"] = vetroAddressData["serviceable"];
        data["address"] = vetroAddressData["address"];
        data["UTYPE"] = vetroAddressData["UTYPE"];
        data["campaign"] = vetroAddressData["campaign"] === undefined ? null : vetroAddressData["campaign"];
        data["phone"] = data["phone"].replaceAll("-", "").trim(); // Sanitize to "XXXXXXXXXX"

        // TODO: Add data for these at some point
        data["caccount"] = null;
        data["ucount"] = null;

        if (Object.prototype.hasOwnProperty.call(data, "businessname")) {
            data["servicelevel"] = "Bus";
        } else {
            data["businessname"] = null; // No business name for resi submissions
        }

        try {
            const submit = await APIService.postContactInfo(JSON.stringify(data));
            setShowAnim(false);
            setShowContactForm(false);

            if (submit.status === 201) {
                setShowSubmissionSuccess(true);

                // push to Vetro, show addr as preregistered
                let preregObj = {
                    "features": [
                        {
                        "type": "Feature",
                        "x-vetro": {
                                "vetro_id": vetroAddressData["vserviceid"]
                            },
                            "properties": {
                                "prereg": "True"
                            }
                        }
                    ]
                }
                VetroService.updateVetroFeature(JSON.stringify(preregObj));
            } else if (submit.status === 200) {
                setShowDuplicateSignUp(true);
            } else {
                setShowOutsideServiceArea(true);
            }

        } catch (error) { // timeout
            setShowAnim(false);
            setShowContactForm(false);
            setShowNetworkError(true);
        }
    }


    const onCommunitySubmit = async (e: FormEvent) => {
        e.preventDefault();
        if (!communityUnitValue) {
            setShowCommunityValueWarning(true);
            return;
        }

        setShowCommunityValueWarning(false);
        setShowAnim(true); // Set spinner

        let queryString = `plan="New Structures" AND ID=${communityUnitValue}`;
        const vetroLocationObj = await VetroService.getVetroAddressMatches(JSON.stringify({"query": queryString}));

        if (vetroLocationObj) {
            if (vetroLocationObj["result"].items[0].attributes["Serviceable"]) {
                if (Object.prototype.hasOwnProperty.call(vetroLocationObj["result"].items[0].attributes, "COS Object ID")) {
                    let cosUrl = `https://citysidefiber.broadbandportal.net/Home/SearchAddress?objectId=${vetroLocationObj["result"].items[0].attributes["COS Object ID"]}`;
                    window.location.replace(cosUrl);
                    return;
                } else if (Object.prototype.hasOwnProperty.call(vetroLocationObj["result"].items[0].attributes, "COS Object Number")) {
                    let cosUrl = `https://citysidefiber.broadbandportal.net/Home/SearchAddress?objectNumber=${vetroLocationObj["result"].items[0].attributes["COS Object Number"]}`;
                    window.location.replace(cosUrl);
                    return;
                }
            }
        // Break if not receiving a response from Vetro
        } else {
            setShowAnim(false);
            setShowNetworkError(true);
        }
    }

    function vetroAddrQueryBuilder(addressData, unitData, checkEmptyUnit, useExactMatch) {
        var baseQuery = 'plan="New Structures"';
        if (Object.prototype.hasOwnProperty.call(addressData, "number")) {
            var streetNumBaseStr = useExactMatch ? ` AND "Street Number"="${addressData.number}"` : ` AND "Street Number"~=${addressData.number}`;
            baseQuery = baseQuery + streetNumBaseStr;
        }

        if (Object.prototype.hasOwnProperty.call(addressData, "street") && addressData.street.length) {
            var streetBaseStr = ` AND "Street Address"~="`;
            var streetStr = "";

            if (Object.prototype.hasOwnProperty.call(addressData, "prefix")) {
                streetStr = streetStr + " " + addressData.prefix;
            }

            streetStr = streetStr + " " + addressData.street;

            if (Object.prototype.hasOwnProperty.call(addressData, "type")) {
                streetStr = streetStr + " " + addressData.type;
            }

            baseQuery = baseQuery + streetBaseStr + streetStr.trim() + '"';
        }

        if (Object.prototype.hasOwnProperty.call(addressData, "city") && addressData.city.length) {
            var cityBaseStr = ` AND City~="${addressData.city}"`;
            baseQuery = baseQuery + cityBaseStr;
        }

        if (unitData !== undefined && unitData !== null && unitData.length) {
            var unitBaseStr = ` AND "Unit Number"~="${unitData}"`;
            baseQuery = baseQuery + unitBaseStr;
        } else if (checkEmptyUnit) {
            var emptyUnitBaseStr = ` AND "Unit Number" !~= APT AND "Unit Number" !~= STE AND "Unit Number" !~= "#" AND "Unit Number" !~= UNIT AND "Unit Number" !~= SPC`;
            baseQuery = baseQuery + emptyUnitBaseStr;
        }

        return baseQuery;
    }

    function titleCaseString(str) {
        var separated = str.toLowerCase().split(' ');
        for (let i = 0, len = separated.length; i < len; i++) {
            separated[i] = separated[i].charAt(0).toUpperCase() + separated[i].substring(1);
        }
        return separated.join(' ');
    }

    async function parseAddressInput(streetInput, unitInput) {
        var parsed = parser.parseLocation(streetInput);
        if (parsed === null) {
            return false;
        }
        var queryString = vetroAddrQueryBuilder(parsed, unitInput, false, false);

        const vetroAddressesObj = await VetroService.getVetroAddressMatches(JSON.stringify({"query": queryString}));

        const vetroAddressStreets: any[] = [];
        const vetroAddressUnits: any[] = [];

        for (let i = 0, len = vetroAddressesObj["result"].items.length; i < len; i++) {
            let cur = vetroAddressesObj["result"].items[i].attributes;
            var unit = "";
            if (Object.prototype.hasOwnProperty.call(cur, "Unit Number")) {
                unit = titleCaseString(cur["Unit Number"]);
            }

            var addressDisplay = `${titleCaseString(cur["Street Address"])}, ${titleCaseString(cur["City"])}, ${cur["State"]} ${cur["Zip"]}`;

            // Only suggested unique street names. Avoid duplicates with HOA/MDUs.
            if (!vetroAddressStreets.includes(addressDisplay)) {
                vetroAddressStreets.push(addressDisplay);
            }

            // Suggest unique units for HOAs/MDUs.
            if (unit.length && !vetroAddressUnits.includes(unit)) {
                vetroAddressUnits.push(unit);
            }
        }

        setVetroAddressOptions(vetroAddressStreets);
        setVetroUnitOptions(vetroAddressUnits);
    }

    const onChange = () => {
        if (formRef.current) {
            setFormData(new FormData(formRef.current));

            let streetInput = formData?.get("street") as string;
            let unitInput = formData?.get("unit") as string;
            if (streetInput !== undefined && streetInput !== null) {
                parseAddressInput(streetInput, unitInput);
                if (streetInput === THE_SECRET_CODE) {
                    setShowCampaign(true);
                    formRef.current.street.value = "";
                }
            }
        }
    }

    const onContactChange = () => {
        if (formContactRef.current) {
            setFormData(new FormData(formContactRef.current));
        }
    }

    const onCommunityUnitSelectChange = (unit) => {
        if (unit.value) {
            setCommunityUnitValue(unit.value);
        }
    };

    useEffect(() => {
        if (formRef.current) {
            setFormData(new FormData(formRef.current));
        }
    }, [formRef.current]);


    useEffect(() => {
        parseAddressInput("999999999999", ""); // Bunk call to connect to Vetro on load

        // Check params, modify form input based on params.
        const windowUrl = window.location.search;
        const params = new URLSearchParams(windowUrl);
        if (params) {
            if (params.get("available") === "true") {
                setShowAvailablePrompt(true);
            }
        }
    }, []);

    return (
    <>
        <Helmet>
            <title>{BASE_PAGE_TILE}: Check Availability</title>
        </Helmet>

        {/* Step 1: Address service checker: */}
        {showAddressForm &&
        <FormContainer5050
            imageD={imageD}
            imageM={imageM}
            imageDesc='mother, daughter, and father looking at tablet computer'
            loadImage='eager'
            loadingAnimationText='Looking up address...'
            showLoadingAnimation={showAnim}
        >
            <form ref={formRef} onSubmit={onSubmit} onChange={onChange}>
                <h1 className='headline__h1' style={{whiteSpace: 'nowrap'}}>
                    {!showAvailablePrompt &&
                        <span className='color--dark-blue'>we&apos;d love to<br/>connect you!</span>
                    }
                    {showAvailablePrompt &&
                        <span className='color--dark-blue'>let&apos;s get<br/>you connected!</span>
                    }
                </h1>

                {!showAvailablePrompt &&
                <p>
                    Enter your address to find out if fiber is available.
                </p>
                }
                {showAvailablePrompt &&
                <p>
                    Fiber Internet Service is live in your area. Enter your address below to begin.
                </p>
                }

                {showTooManyVetroResults &&
                    <p>
                        Just a sec, our system is having trouble finding your address. Please confirm your full address, including your unit number if you have one.
                    </p>
                }

                <div className='input-container'>
                    <label htmlFor='street'>Street Address</label>
                    <input
                        id='street'
                        name='street'
                        type='text'
                        list='vetroAddressOptions'
                        autoComplete='off'
                        placeholder="Street Address"
                        required
                    />
                    <datalist id="vetroAddressOptions">
                        {vetroAddressOptions.map((o) => (
                            <option key={o}>{o}</option>
                        ))}
                    </datalist>
                </div>

                <div className='multi-column-container'>
                    <div className='input-container'>
                        <label htmlFor='unit'>Unit #</label>
                        <input
                            id='unit'
                            name='unit'
                            type='text'
                            list='vetroUnitOptions'
                            autoComplete='off'
                        />
                        <datalist id="vetroUnitOptions">
                            {vetroUnitOptions.map((o) => (
                                <option key={o}>{o}</option>
                            ))}
                        </datalist>
                    </div>

                    <div className='select-container'>
                        <label htmlFor='type'>Type</label>
                        <select name='type' defaultValue='residential' required>
                            <option value='R'>Residential</option>
                            <option value='B'>Business Services</option>
                        </select>
                    </div>
                </div>

                {showCampaign &&
                    <div className='input-container'>
                        <label htmlFor='campaign'>Campaign</label>
                        <select id='campaign' name='campaign'>
                            <option disabled selected> -- Select a Campaign -- </option>
                            <option value='Tyler'>Tyler</option>
                            <option value='Frank'>Frank</option>
                        </select>
                    </div>
                }

                <Button
                    type='submit'
                    className='btn--lg-extra-padding'
                    size='md-to-lg'
                    fullWidth={true}
                >
                    {!showAvailablePrompt &&
                        <p>
                            Check Availability
                        </p>
                    }
                    {showAvailablePrompt &&
                        <p>
                            Submit
                        </p>
                    }
                </Button>


            </form>
        </FormContainer5050>
        }

        {/* Step 2a: Contact form: */}
        {showContactForm &&
        <FormContainer5050
            imageD={imageD}
            imageM={imageM}
            imageDesc='mother, daughter, and father looking at tablet computer'
            loadImage='eager'
            loadingAnimationText='Submitting inquiry...'
            showLoadingAnimation={showAnim}
        >
            <form ref={formContactRef} onSubmit={onContactSubmit} onChange={onContactChange}>
                <h1 className='headline__h1' style={{whiteSpace: 'nowrap'}}>
                    <span className='color--dark-blue'>let&#39;s connect!</span>
                </h1>

                {showInsideServicableCities &&
                    <p>
                        We are in active construction in your area now, but your address is not in service just yet.
                        Please register your interest below so we can connect you as soon as service is available.
                    </p>
                }
                {!showInsideServicableCities &&
                    <p>
                        Your address is outside our current service area, but we are expanding rapidly in Orange County.
                        Please register your interest below so we can connect you as soon as service is available.
                    </p>
                }

                <div className='multi-column-container'>
                    <div className='input-container'>
                        <label htmlFor='street'>First Name</label>
                        <input
                            id='firstname'
                            name='firstname'
                            type='text'
                            autoComplete='off'
                            required
                        />
                    </div>

                    <div className='input-container'>
                        <label htmlFor='street'>Last Name</label>
                        <input
                            id='lastname'
                            name='lastname'
                            type='text'
                            autoComplete='off'
                            required
                        />
                    </div>
                </div>

                <div className='multi-column-container'>
                    <div className='input-container'>
                        <label htmlFor='tel'>Phone</label>
                        <input
                            id='phone'
                            name='phone'
                            type='tel'
                            pattern='[0-9]{3}-?[0-9]{3}-?[0-9]{4}'
                            placeholder='XXX-XXX-XXXX'
                            required
                        />
                    </div>

                    <div className='input-container'>
                        <label htmlFor='email'>Email</label>
                        <input
                            id='email'
                            name='email'
                            type='email'
                            required
                        />
                    </div>
                </div>

                {/* Show service level when residential is selected */}
                {showServiceLevelDropdown &&
                <div className='select-container'>
                    <label htmlFor='servicelevel'>Service Level</label>
                    <select id='servicelevel' name='servicelevel' required>
                        <option value='1gig'>The Point - 1 Gig Connect ($80/mo.)</option>
                        <option value='2gig'>The Wedge - 2 Gig Connect ($95/mo.)</option>
                        <option value='5gig'>The Monarch - 5 Gig Connect ($195/mo.)</option>res
                    </select>
                </div>
                }

                {/* If business, ask for business name instead */}
                {!showServiceLevelDropdown &&
                <div className='input-container'>
                    <label htmlFor='businessname'>Business Name</label>
                    <input
                        id='businessname'
                        name='businessname'
                    />
                </div>
                }

                {showEmailPrompt &&
                    <p>
                        Please enter an email address.
                    </p>
                }

                <Button
                    type='submit'
                    className='btn--lg-extra-padding'
                    size='md-to-lg'
                    fullWidth={true}
                >
                    Submit Your Interest
                </Button>
            </form>
        </FormContainer5050>
        }

        {/* Step 2b: Community form: */}
        {showCommunityForm &&
        <FormContainer5050
            imageD={imageD}
            imageM={imageM}
            imageDesc='mother, daughter, and father looking at tablet computer'
            loadImage='eager'
            loadingAnimationText='Getting you ready!'
            showLoadingAnimation={showAnim}
        >
            <form ref={formContactRef} onSubmit={onCommunitySubmit}>
                <h1 className='headline__h1' style={{whiteSpace: 'nowrap'}}>
                    <span className='color--dark-blue'>we&#39;re here!</span>
                </h1>

                <p>
                    It looks like you&apos;re located at the {communityName} community. Let&apos;s get you connected!
                </p>

                <p>
                    Please select your unit # from the list below and then Sign Up Now.
                </p>

                <div className='input-container'>
                    <label htmlFor='communityUnit'>Unit</label>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        isSearchable={communityFormIsSearchable}
                        name="color"
                        options={communityUnitOptions}
                        onChange={onCommunityUnitSelectChange}
                    />
                    {showCommunityValueWarning && (
                        <div style={{ color: 'blue' }}>Please select your unit from this list!</div>
                    )}
                </div>

                <Button
                    type='submit'
                    className='btn--lg-extra-padding'
                    size='md-to-lg'
                    fullWidth={true}
                >
                    Sign Up Now
                </Button>

                <p>
                    If this doesn&apos;t match your unit, please email us at <a href="mailto:concierge@citysidefiber.com"> concierge@citysidefiber.com</a>, and we will help you set it up.
                </p>
            </form>
        </FormContainer5050>
        }

        {/* Step 3: Landing page, interest successfully submitted! */}
        {/* Not technically a form but we want to keep the same page layout */}
        {showSubmissionSuccess &&
        <FormContainer5050
            imageD={imageD}
            imageM={imageM}
            imageDesc='mother, daughter, and father looking at tablet computer'
            loadImage='eager'
        >
            <form>
                <h1 className='headline__h1' style={{whiteSpace: 'nowrap'}}>
                    <span className='color--dark-blue'>thanks for<br />your interest!</span>
                </h1>
                <p>
                    Our Concierge team will reach out with more information shortly. In the meantime...
                </p>
                <ul>
                    <li style={{listStyleType: 'circle'}}>Curious about streaming TV options, take our personalized quiz:
                        <a href="https://streaming.citysidefiber.com/start" target="_blank" rel="noreferrer noopener"><span style={{color:'blue'}}> link</span></a>
                    </li>
                    <br/>
                    <li style={{listStyleType: 'circle'}}>Do you live in an HOA or multifamily community?
                        To connect you faster, please shoot us an email at <a href="mailto:hello@citysidefiber.com">hello@citysidefiber.com </a>
                        to help us get in touch with your representatives.
                    </li>
                </ul>
            </form>
        </FormContainer5050>
        }

        {/* No addr matches and/or anything outside CA */}
        {showOutsideServiceArea &&
        <FormContainer5050
            imageD={imageD}
            imageM={imageM}
            imageDesc='mother, daughter, and father looking at tablet computer'
            loadImage='eager'
        >
            <form>
                <h2 className='headline__h2' style={{whiteSpace: 'nowrap'}}>
                    <span className='color--dark-blue'>bummer, it looks like<br />you&#39;re out of our area</span>
                </h2>
                <p>
                    Our focus right now is on connecting Orange County.
                    If you feel like your address should be in our service area, please shoot us an email at
                    <a href="mailto:concierge@citysidefiber.com"> concierge@citysidefiber.com.</a>
                </p>
            </form>
        </FormContainer5050>
        }

        {/* Location has already signed up */}
        {showDuplicateSignUp &&
        <FormContainer5050
            imageD={imageD}
            imageM={imageM}
            imageDesc='mother, daughter, and father looking at tablet computer'
            loadImage='eager'
        >
            <form>
                <h2 className='headline__h2' style={{whiteSpace: 'nowrap'}}>
                    <span className='color--dark-blue'>hmm, looks like<br />you already signed up!</span>
                </h2>
                <p>
                    We know you&apos;re eager for us to bring service to you, and we&apos;re eager to connect you!
                    Rest assured, we already have your registered interest in our system.
                    If you think that&apos;s incorrect, please shoot us a note at
                    <a href="mailto:concierge@citysidefiber.com"> concierge@citysidefiber.com.</a>
                </p>
            </form>
        </FormContainer5050>
        }

        {/* Unable to contact API */}
        {showNetworkError &&
        <FormContainer5050
            imageD={imageD}
            imageM={imageM}
            imageDesc='mother, daughter, and father looking at tablet computer'
            loadImage='eager'
        >
            <form>
                <h2 className='headline__h2' style={{whiteSpace: 'nowrap'}}>
                    <span className='color--dark-blue'>hmm, we&apos;re running<br />into a little trouble...</span>
                </h2>
                <p>
                    It looks like we&apos;re having some issues registering your interest.
                    We know you&apos;re eager for us to bring service to you, and we&apos;re eager to connect you!
                    Rest assured, you can check back later or shoot us a note at
                    <a href="mailto:concierge@citysidefiber.com"> concierge@citysidefiber.com.</a>
                </p>
            </form>
        </FormContainer5050>
        }
    </>
    )
};

export default CheckAvailabilityPage;